import ApiService from '@core/api/services/ApiService';
import UrlResolver from '@core/url-resolver/UrlResolver';
import debounce from 'lodash/debounce';
import { useRef, useState } from 'react';
const useNearestDepot = () => {
    const [depot, setNearestDepot] = useState(null);
    const searchDepots = async (searchTerm) => {
        const response = await ApiService.request({
            baseUrl: UrlResolver.getDomain(),
            method: 'GET',
            controller: 'depots',
            slug: 'search',
            params: {
                searchTerm,
            },
        });
        if (response.validationErrors && response.validationErrors.length > 0) {
            return;
        }
        setNearestDepot(response.result);
    };
    const debouncedSearch = useRef(debounce((s) => searchDepots(s), 275));
    return {
        depot,
        searchDepots: debouncedSearch.current,
    };
};
export default useNearestDepot;
