import { HydrateOption } from '@core/enums';
import { Grid } from '@helpers/grid';
import withWidget from '@hoc/withWidget';
import useNearestDepot from '@hooks/useNearestDepot';
import { ReactComponent as Chevron } from '@img/icons/chevron.svg';
import { ReactComponent as Search } from '@img/icons/search.svg';
import MapSvgUrl from '@img/map-bg.svg';
import Heading from '@stories/Components/Global/Typography/Heading';
import Paragraph from '@stories/Components/Global/Typography/Paragraph';
import Map from '@stories/Widgets/Misc/Map/Map';
import React, { useEffect, useRef, useState } from 'react';
import { DepotLocatorStyles as s } from './DepotLocator.styles';
const DepotLocator = (props) => {
    const searchRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const { depot, searchDepots } = useNearestDepot();
    /** Searches depots on search change */
    useEffect(() => {
        if (searchTerm.length === 0 || !searchRef.current) {
            return;
        }
        searchDepots(searchTerm);
        searchRef.current.focus();
    }, [searchTerm]);
    return (React.createElement(s.Wrapper, null,
        React.createElement(Grid.Default, null,
            React.createElement(s.Background, { src: MapSvgUrl, loading: "lazy", alt: "Depot Locator Background" }),
            React.createElement(s.ContentArea, null,
                props.title ? (React.createElement(Heading, { semantic: "h1", visual: "h5" }, props.title)) : null,
                props.description ? React.createElement(Paragraph, { size: "regular" }, props.description) : null,
                props.learnMoreText && props.learnMoreUrl ? (React.createElement(s.Link, { href: props.learnMoreUrl },
                    props.learnMoreText,
                    " ",
                    React.createElement(Chevron, null))) : null,
                React.createElement(s.SearchWrapper, null,
                    React.createElement("input", { ref: searchRef, type: "text", placeholder: props.searchPlaceholder, value: searchTerm, onChange: (e) => setSearchTerm(e.currentTarget.value) }),
                    React.createElement("button", { type: "button", onClick: () => searchDepots(searchTerm) },
                        React.createElement(Search, null)))),
            React.createElement(s.MapArea, null,
                React.createElement(Map, { googleMapsApiKey: props.googleMapsApiKey, markers: searchTerm.length > 0 && depot ? [depot] : props.initialDepots, center: props.center, zoom: 6 })))));
};
export default withWidget(DepotLocator, 'DepotLocator', { hydrate: HydrateOption.InView });
